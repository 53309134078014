import React from 'react'
import {Row, Col, Button, Typography} from 'antd'
import {useNavigate, Link} from 'react-router-dom'
import {FaSignOutAlt} from 'react-icons/fa'
import Logo from '../../assets/logo.png'
import './styles.css'
import {jwt_token} from "../../store";
import {useSetAtom} from 'jotai'

const {Text, Title} = Typography
const Navbar = ({navText, to}) => {

    const clearSession = useSetAtom(jwt_token)

    const navigate = useNavigate()
    const handleLogout = () => {
        localStorage.removeItem('token')
        clearSession('')
        navigate('/')
    }
    return (
        <Row className='navbar'>
            <Col xs={24} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <img src={Logo} alt="Gems logo" className='gems-logo'/>

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 15}}>
                    <Link to={to}>
                        <Title level={5} style={{padding: 0, margin: 0}}>{navText}</Title>
                    </Link>
                    <Button className='logoutBtn' onClick={handleLogout}>
                        <Text style={{color: '#fff', fontWeight: 500}} className='logoutText'>Logout</Text>
                        <FaSignOutAlt size={20}/>
                    </Button>
                </div>
            </Col>
        </Row>
    )
}

export default Navbar