import React, { useEffect, useState } from 'react'
import Preloader from '../helpers/preloader'
import LoginForm from '../components/login'

const Login = () => {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])
  return (
    isLoading ? <Preloader /> : <LoginForm />
  )
}

export default Login