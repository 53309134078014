import React, { useEffect, useState } from 'react'
import Preloader from '../helpers/preloader'
import Navbar from '../components/agent/navbar'
import ProfilePage from '../components/agent/profile'

const Profile = () => {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  return (
    isLoading ? <Preloader /> : 
    <>
      <Navbar navText={'Home'} to={'/staff/home'} />
      <ProfilePage/>
    </>
  )
}

export default Profile