import React from 'react'
import { Link } from "react-router-dom";
import { Typography } from 'antd'
import Logo from '../assets/logo.png'
import './styles.css'
import { BsFillGridFill, BsBarChartLineFill } from 'react-icons/bs'
import { FaBell } from 'react-icons/fa'
import { IoSettings } from 'react-icons/io5'
import AdminLogo from './admin-logo';

const { Text, Title } = Typography

const Navigation = (props) => {
    return (
        <>
            <div className='sideNavParent'>
                <div className='sideNav'>
                    <div className='seniorman'>
                        <div className="logoParent">
                            <div span={24} className='logo gems'>
                                <img src={Logo} alt="Logo" className='logoIcon' width={30} />
                            </div>
                            <div span={24} className='logo aff'>
                                <AdminLogo logoIcon={'logoIcon'} affiliate={props.affiliate}/>
                            </div>
                        </div>
                        <div className='aff-name'><Title level={5} style={{ color: '#fff' }}>{props.affiliate}</Title></div>
                    </div>
                    <ul className='navItems'>
                        <Link to={'/admin/dashboard'} className='navItem'>
                            <BsFillGridFill size={25} className='navIcon' />
                            <Text className='link-text'>Dashboard</Text>
                        </Link>
                        <Link to={'/admin/complaints'} className='navItem'>
                            <FaBell size={25} className='navIcon' />
                            <Text className='link-text'>Complaints <span>{props.count}</span></Text>
                        </Link>
                        <Link to={'/admin/statistics'} className='navItem'>
                            <BsBarChartLineFill size={25} className='navIcon' />
                            <Text className='link-text'>Statistics</Text>
                        </Link>
                        <Link to={'/admin/settings'} className='navItem'>
                            <IoSettings size={25} className='navIcon' />
                            <Text className='link-text'>Settings</Text>
                        </Link>
                    </ul>
                </div>
            </div>
            <div className='bottomNavParent'>
                <div className='bottomNav'>
                    <Link to={'/admin/dashboard'}><BsFillGridFill size={25} className='navIcon' /></Link>
                    <Link to={'/admin/complaints'}><FaBell size={25} className='navIcon' /></Link>
                    <Link to={'/admin/statistics'}><BsBarChartLineFill size={25} className='navIcon' /></Link>
                    <Link to={'/admin/settings'}><IoSettings size={25} className='navIcon' /></Link>
                </div>
            </div>
        </>
    )
}

export default Navigation