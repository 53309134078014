import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { colors } from './chart-colors';
import { BarChartData } from './compute-data';


export const ChartLine = () => {
    const complaints = []
    const { Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec } = BarChartData(complaints)

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'This Year',
                data: [Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec],
                ...colors
            }
        ]
    }
    return (
        <Line data={data}
            width={450}
            height={250}
            options={{
                maintainAspectRatio: false
            }}
        />
    )
}
