import React, { useEffect, useState } from 'react'
import Preloader from '../helpers/preloader'
import Layout from '../layout';
import Home from '../components/home'
import { ApiClient } from '../axios-client';
import { useAtomValue, useSetAtom } from 'jotai';
import { complaintData, staffData, tokenData, usersData } from '../store';
import { message } from 'antd';


const Dashboard = () => {
    const [isLoading, setLoading] = useState(true)

    const user = useAtomValue(tokenData)
    
    const setUsers = useSetAtom(usersData)
    const setStaff = useSetAtom(staffData)
    const setComplaints = useSetAtom(complaintData)

    useEffect(() => {
        (async () => {
            try {
                const users = await ApiClient.get(`/users/complainants`)
                const complaints = await ApiClient.get(`/complaints/filter/${user?.affiliate}`)
                const staffs = await ApiClient.get(`/users/staffs`)
                setComplaints(complaints.data)
                setUsers(users.data)
                setStaff(staffs.data)
                
                setLoading(false)
            } catch (error) {
                message.error(error.message)
            }
        })()
    },[setComplaints, setUsers, user.affiliate, setStaff])

    return (
        isLoading ? <Preloader/> :
        <Layout title={'Dashboard'}>
            <Home/>
        </Layout>
    )
}

export default Dashboard;