import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { Navigate } from "react-router-dom"

function Protected({ children }) {
    const token = localStorage.getItem('token')
    useEffect(() => {
        if(token.length === 0) {
            message.error("Access denied, please login first.")
        }
    }, [token])
    return token ? children : <Navigate to="/login" />
}
export default Protected;