import React, { useState, useEffect } from 'react'
import { MdMenuOpen, MdAddCircle, MdEdit } from 'react-icons/md'
import { FaUserAlt, FaTrash } from 'react-icons/fa'
import { Typography, Row, Col } from 'antd'
import RegisterForm from './register-form'
import RenderAgents from './render-agents'
import DeleteForm from './delete'
import UpdateForm from './update-form'
import './styles/settings.css'

const { Title } = Typography

const Setting = ({agents}) => {
    const [all, setAll] = useState(true)
    const [new_, setNew] = useState(false)
    const [edit, setEdit] = useState(false)
    const [remove, setRemove] = useState(false)
    const [ data, setData ] = useState(null)

    const handleAll = () => {
        setAll(true)
        setNew(false)
        setEdit(false)
        setRemove(false)
    }

    const handleNew = () => {
        setAll(false)
        setNew(true)
        setEdit(false)
        setRemove(false)
    }

    const handleEdit = () => {
        setAll(false)
        setNew(false)
        setEdit(true)
        setRemove(false)
    }

    const handleRemove = () => {
        setAll(false)
        setNew(false)
        setEdit(false)
        setRemove(true)
    }
    return (
        <>
            <Row gutter={[20, 52]} align={'middle'} justify={'center'} style={{ marginBottom: 10 }}>
                <Col span={24}>
                    <Title level={4} style={{ color: '#00115B', display: 'flex', alignItems: 'center', gap: 5 }}><MdMenuOpen size={30} /> Menu</Title>
                    <ul className={'menu'}>
                        <li className={'menuItem'} onClick={handleAll}>
                            <FaUserAlt size={20}/> <span className={'nav-text'}>All Agents</span>
                        </li>
                        <li className={'menuItem'} onClick={handleNew}>
                            <MdAddCircle size={20}/> <span className={'nav-text'}>New Agent</span>
                        </li>
                        <li className={'menuItem'} onClick={handleEdit}>
                            <MdEdit size={20}/> <span className={'nav-text'}>Edit Agent</span>
                        </li>
                        <li className={'menuItem'} onClick={handleRemove}>
                            <FaTrash size={20}/> <span className={'nav-text'}>Remove Agent</span>
                        </li>
                    </ul>
                </Col>
            </Row>

            {all ? <RenderAgents agents={agents}/> : <></>}
            {new_ ? <RegisterForm /> : <></>}
            {edit ? <UpdateForm agents={agents}/> : <></>}
            {remove ? <DeleteForm agents={agents}/> : <></>}

        </>
    )
}

export default Setting