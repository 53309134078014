import React, { useEffect, useState } from 'react'
import { Spin, Row, Col, Skeleton, Divider, Typography } from 'antd'
import { BiErrorCircle } from 'react-icons/bi'
import { BsCheckCircle } from 'react-icons/bs'
import Card from './card'
import '../complaints/styles.css'
import { useAtomValue } from 'jotai'
import { complaintData } from '../../store'



const AlertCard = () => {

    const { Title } = Typography

    // States
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(false)
    }, [])
    
    const complaints = useAtomValue(complaintData)

    return (
        <Row align={'middle'} justify={'center'} gutter={[20, 20]} style={{ padding: '20px 50px' }}>
            {loading ?
                <Col span={24}>
                    <Skeleton active style={{ marginTop: 50 }} />
                </Col> :
                <Row align={'middle'} justify={'center'} gutter={[20, 20]} style={{ width: '100%' }}>
                    <Title level={3} style={{margin: 0}}>Complaints</Title>
                    <Divider orientation='center' style={{ width: '20%' }}>Unread</Divider>
                    {
                        complaints === [] ? <Spin size='large' spinning /> :
                            complaints.map((item, index) => {
                                return !item.resolved &&
                                    <Card
                                        identity={item._id}
                                        key={index}
                                        borderColor={'#ff000080'}
                                        bgColor={'#e924240d'}
                                        isResolved={true}
                                        index={index}
                                        icon={<BiErrorCircle size={25} color={'#ff000080'} />}
                                        details={{ content: item.content, category: item.category, type: item.type, date: item.createdAt, resolved: item.resolved }}
                                    />
                            }).reverse()
                    }
                    <Divider orientation='center' style={{ width: '20%' }}>Read</Divider>
                    {
                        complaints === [] ? <Spin size='large' spinning /> :
                            complaints.map((item, index) => {
                                return item.resolved &&
                                    <Card
                                        identity={item._id}
                                        key={index}
                                        borderColor={'#80E3A8'}
                                        bgColor={'#80e3a826'}
                                        isResolved={true}
                                        index={index}
                                        icon={<BsCheckCircle size={25} color={'#80E3A8'} />}
                                        details={{ content: item.content, category: item.category, type: item.type, date: item.createdAt, resolved: item.resolved }}
                                    />
                            }).reverse()
                    }
                </Row>
            }
        </Row>
    )
}

export default AlertCard