import React, { useEffect, useState } from 'react'
import Preloader from '../helpers/preloader'

import Homepage from '../components/agent/index.js'
import { ApiClient } from '../axios-client';
import { useAtomValue, useSetAtom } from 'jotai';
import { complaintData, tokenData } from '../store';
import { message } from 'antd';

const Agent = () => {
    // States
    const [isLoading, setLoading] = useState(true)

    const user = useAtomValue(tokenData)
    const setComplaints = useSetAtom(complaintData)

    useEffect(() => {
        (async () => {
            try {
                const complaints = await ApiClient.get(`/complaints/filter/${user.affiliate}`)
                setComplaints(complaints.data)
                setLoading(false)
            } catch (error) {
                message.error(error.message)
            }
        })()
    })

    return (
        isLoading ? <Preloader /> :
            <Homepage />
    )
}

export default Agent