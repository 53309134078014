import React, { useEffect, useState } from 'react'
import Preloader from '../helpers/preloader'
import Layout from '../layout'
import Notification from '../components/complaints'


const Complaints = () => {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  return (
    isLoading ? <Preloader/> :
    <Layout title={'Complaints'}>
      <Notification/>
    </Layout>
  )
}

export default Complaints