import React, { useState, useEffect, useCallback } from 'react'
import { Card, Skeleton, Row, Col, Image, Typography, Switch, Collapse, message } from 'antd'
import { FaRegUserCircle } from 'react-icons/fa'
import { BsChatText, BsTelephone, BsFillImageFill, BsClock } from 'react-icons/bs'
import { MdDateRange, MdOutlineLocationOn, MdLocationPin, MdOutlineCategory } from 'react-icons/md'
import '../complaints/styles.css'
import { ApiClient } from '../../axios-client'
import { useAtomValue } from 'jotai'
import { tokenData } from '../../store'

const { Title, Text } = Typography
const { Panel } = Collapse

const Preview = ({ complaint }) => {
    const { _id, category, content, createdAt, descLocation, geoLocation, imageUrl, resolved, type, complainant } = complaint[0];
    const [checked, setChecked] = useState(resolved)
    const [loading, setLoading] = useState(true);
    const [checker, setChecker] = useState(false);


    const user = useAtomValue(tokenData)

    const handleResolved = useCallback(async () => {
        try {
            const response = await ApiClient.patch(`/complaint/${_id}`, { resolved: checked, attendant: user._id })
            if (response.status === 200) setChecker(true)
        } catch (error) {
            message.error(error.response.data.message)
        }
    }, [checked, _id, user._id])

    useEffect(() => {
        setLoading(false)
        handleResolved()
    }, [handleResolved])


    return (
        <Row gutter={[0, 32]} style={{ padding: '20px 50px' }}>
            {
                loading ?
                    <Col span={24}><Skeleton active /></Col>
                    :
                    <Col span={24}>
                        <Card className='previewCard'>
                            <Row gutter={[20, 20]}>
                                <Col span={24}><Title level={5}>{category}</Title></Col>
                                <Col xs={24} md={12}>
                                    <Row gutter={[0, 20]}>
                                        <Col xs={24}>
                                            <Collapse className={'collapse'} >
                                                <Panel header={'Complaint details'} extra={<BsChatText size={20} color={'#e92424cc'} />}>
                                                    <div className={'panelInner'}>
                                                        <Text className='text'>
                                                            <BsChatText size={20} color={'#e92424cc'} />
                                                            <span>{content}</span>
                                                        </Text>
                                                        <Text className='text'>
                                                            <MdOutlineCategory size={20} color={'#00bc6d'} /> <b>Complaint type </b> <span>[ {type}]</span>
                                                        </Text>
                                                        <Text className='text'>
                                                            <MdDateRange size={20} color={'#c2c218'} />
                                                            <b>Date: <span style={{ fontWeight: 'normal' }}>{createdAt.split('T')[0]}</span></b>
                                                        </Text>
                                                        <Text className='text'>
                                                            <BsClock size={20} color={'#000'} />
                                                            <b>Time: <span style={{ fontWeight: 'normal' }}>{createdAt.split('T')[1].split('.')[0]}</span></b>
                                                        </Text>
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                        </Col>

                                        <Col span={24}>
                                            <Collapse className={'collapse'} >
                                                <Panel header={'Location details'} extra={<MdOutlineLocationOn size={23} color={'#00bc6d'} />}>
                                                    <div className={'panelInner'}>
                                                        <Text className='text' style={{ fontWeight: 600, color: '#00bc6d' }}><MdLocationPin size={20} /><span>Complainant&apos;s description</span></Text>
                                                        <ul className='list'><li>{descLocation}</li></ul>
                                                        <Text className='text' style={{ fontWeight: 600, color: '#00bc6d' }}><MdOutlineLocationOn size={20} /><span>Generated location (complainant&apos;s device GPS)</span></Text>
                                                        <div className='list'>
                                                            <Text className='text'><b>City: </b>{geoLocation.city === '' || geoLocation.city === undefined ? 'Unavailable' : geoLocation.city}</Text>
                                                            <Text className='text'><b>District: </b>{geoLocation.locality === '' || geoLocation.locality === undefined ? 'Unavailable' : geoLocation.locality}</Text>
                                                            <Text className='text'>
                                                                <b>Coordinates (Lat,Long): </b>
                                                                {
                                                                    geoLocation.latitude === '' || geoLocation.latitude === undefined ? 'Unavailable' :
                                                                        geoLocation.longitude === '' || geoLocation.longitude === undefined ? 'Unavailable' :
                                                                            geoLocation.latitude + ' , ' + geoLocation.longitude
                                                                }
                                                            </Text>
                                                        </div>
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                        </Col>
                                        <Col span={24}>
                                            <Collapse className={'collapse'} >
                                                <Panel header={'Complainant'} extra={<FaRegUserCircle size={20} color={'#c2c218'} />}>
                                                    <div className={'panelInner'}>
                                                        <Text className='text'><FaRegUserCircle size={20} color={'#00bc6d'} /> {complainant.firstname + ' ' + complainant.lastname}</Text>
                                                        <Text className='text'><BsTelephone size={20} color={'#e92424cc'} /> {'+233' + complainant.contact}</Text>
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Collapse className={'collapse'} >
                                        <Panel header={'Image content'} extra={<BsFillImageFill size={20} color={'#ff0a53'} />}>
                                            <div style={{ width: '100%', background: '#ccc' }}>
                                                <Image
                                                    width={'100%'}
                                                    alt={'Complaint image'}
                                                    src={imageUrl}
                                                    placeholder={
                                                        <Image
                                                            alt={'Complaint image'}
                                                            preview={false}
                                                            src={imageUrl}
                                                            width={'100%'}
                                                            height={'auto'}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 30 }}>
                                <Col span={24}>
                                    <Text>Resolved state </Text>
                                    <Switch checked={checked} onClick={() => {
                                        setChecked(!checked)
                                        checker && message.success('Complaint updated')
                                    }} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
            }
        </Row>
    )
}


export default Preview