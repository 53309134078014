export const colors = {
    backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(201, 203, 27, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(75, 92, 19, 0.2)',
        'rgba(25, 205, 86, 0.2)',
        'rgba(153, 10, 255, 0.2)',
        'rgba(154, 162, 235, 0.2)',
        'rgba(54, 16, 235, 0.2)',
    ],
    borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(201, 203, 27)',
        'rgb(75, 192, 192)',
        'rgb(255, 205, 86)',
        'rgb(153, 102, 255)',
        'rgb(54, 162, 235)',
        'rgb(75, 92, 19)',
        'rgb(25, 205, 86)',
        'rgb(153, 10, 255)',
        'rgb(154, 162, 235)',
        'rgb(54, 16, 235)',
    ],
    borderWidth: 1,
    hoverBackgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(201, 203, 27)',
        'rgb(75, 192, 192)',
        'rgb(255, 205, 86)',
        'rgb(153, 102, 255)',
        'rgb(54, 162, 235)',
        'rgb(75, 92, 19)',
        'rgb(25, 205, 86)',
        'rgb(153, 10, 255)',
        'rgb(154, 162, 235)',
        'rgb(54, 16, 235)',
    ]

}