import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Collapse, Typography, Button } from 'antd'
import { FaCheckCircle } from 'react-icons/fa'
import { AiFillCloseCircle } from 'react-icons/ai'
import './styles.css'

const Card = ({ borderColor, index, bgColor, icon, details, identity }) => {
    const { Panel } = Collapse
    const { Title } = Typography

    const { content, category, type, date, resolved } = details
    return (
        <Col xs={24}>
            <Collapse className='collapse' style={{ borderLeft: `10px solid ${borderColor}`, background: bgColor }}>
                <Panel header={content.length > 20 ? content.substring(0, 20) + '...': content} key={index} extra={icon}>
                    <Row gutter={[0, 20]}>
                        <Col xs={24} lg={18}>
                            <Row>
                                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Title level={5}>Content: <span style={{ fontWeight: 'normal' }}>{content}</span></Title>
                                </Col>
                                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Title level={5}>To: <span style={{ fontWeight: 'normal' }}>{category}</span></Title>
                                </Col>
                                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Title level={5}>Type: <span style={{ fontWeight: 'normal' }}>{type}</span></Title>
                                </Col>
                                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Title level={5}>Date: <span style={{ fontWeight: 'normal' }}>{date.split('T')[0]}</span></Title>
                                </Col>
                                <Col span={24} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                    <Title level={5} style={{ margin: 0 }}>Resolved: </Title>
                                    <span>
                                        {resolved ? (<FaCheckCircle size={20} color={'#80E3A8'} />) : <AiFillCloseCircle size={20} color={'#ff000080'} />}
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} lg={6} style={{ display: 'flex', alignItems: 'center' }}>
                            <Link to={`/admin/complaints/${identity}`}>
                                <Button title='More details' className='previewBtn' style={{ background: borderColor }}>Preview Complaint</Button>
                            </Link>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </Col>
    )
}

export default Card