import React, { useState } from 'react'
import { nanoid } from 'nanoid'
import { FaLock, FaAddressCard } from 'react-icons/fa'
import { BsFillTelephoneFill, BsEnvelopeFill } from 'react-icons/bs'
import { RiShieldStarFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { Card, Row, Col, Button, Input, Typography, message } from 'antd'
import { validateAgent } from '../../helpers/form-validation'
import './styles/register.css'
import { ApiClient } from '../../axios-client'
import { useAtomValue } from 'jotai'
import { tokenData } from '../../store'

const { Title } = Typography
const { Password } = Input

const RegisterForm = () => {
    const user = useAtomValue(tokenData)
    const Navigate = useNavigate()

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [rank, setRank] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [contact, setContact] = useState('')
    const [password, setPassword] = useState('')

    const resetForm = () => {
        setFirstname('')
        setLastname('')
        setEmail('')
        setUsername('')
        setRank('')
        setContact('')
        setPassword('')
    }

    const handleRegister = async () => {
        const data = {
            firstname,
            lastname,
            email,
            username,
            rank,
            contact,
            role: 'staff',
            affiliate: user.affiliate,
            password,
        }
        const { error } = validateAgent.validate({ firstname, lastname, email,username, rank, contact, password })
        if (error) {
            message.error(error.message)
        } else {
            try {
                const res = await ApiClient.post('/user/new', data)
                if (res.status === 200) {
                    resetForm()
                    message.success('User successfully created')
                    Navigate('/admin/dashboard')
                }
            } catch (error) {
                message.error(error.response.data.message)
            }
        }

    }

    return (
        <Card className={'registerCard registerCard'}>
            <Row justify='center' align='midde' gutter={[20, 20]} className={'cardRow'}>
                <Col span={24} style={{ textAlign: 'center' }}><Title level={4} style={{ color: '#00115b', fontWeight: 600 }}>Register Agent</Title></Col>
                <Col span={24}>
                    <Input prefix={<FaAddressCard color='#91A2B8' size={16} style={{ marginRight: 10 }} />} type={'text'} placeholder='First Name' value={firstname} className={'input'} onChange={(e) => setFirstname(e.target.value)} />
                </Col>
                <Col span={24}>
                    <Input prefix={<FaAddressCard color='#91A2B8' size={16} style={{ marginRight: 10 }} />} type={'text'} placeholder='Last Name' value={lastname} className={'input'} onChange={(e) => setLastname(e.target.value)} />
                </Col>
                <Col span={24}>
                    <Input prefix={<BsEnvelopeFill color='#91A2B8' size={16} style={{ marginRight: 10 }} />} type={'text'} placeholder='Email Address' value={email} className={'input'} onChange={(e) => setEmail(e.target.value)} />
                </Col>
                <Col span={24}>
                    <Input prefix={<BsEnvelopeFill color='#91A2B8' size={16} style={{ marginRight: 10 }} />} type={'text'} placeholder='Username' value={username} className={'input'} onChange={(e) => setUsername(e.target.value)} />
                </Col>
                <Col span={24}>
                    <Input prefix={<RiShieldStarFill color='#91A2B8' size={16} style={{ marginRight: 10 }} />} type={'text'} placeholder='Position/Rank' value={rank} className={'input'} onChange={(e) => setRank(e.target.value)} />
                </Col>
                <Col span={24}>
                    <Input prefix={<BsFillTelephoneFill color='#91A2B8' size={16} style={{ marginRight: 10 }} />} type={'text'} placeholder='Contact' value={contact} className={'input'} onChange={(e) => setContact(e.target.value)} />
                </Col>
                <Col span={24} style={{ display: 'flex', gap: 10 }}>
                    <Password type={'text'} prefix={<FaLock color='#91A2B8' size={16} style={{ marginRight: 10 }} />} placeholder='Agent ID' value={password} className={'input'} style={{ width: '60%' }} />
                    <Button onClick={() => setPassword(nanoid(6))} style={{ width: '40%' }} >Generate ID</Button>
                </Col>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button className={'btn'} onClick={handleRegister}>Register</Button>
                </Col>
            </Row>
        </Card>
    )
}

export default RegisterForm