import React, { useState } from 'react'
import { Row, Col, Input, Button, Typography } from 'antd'
import { FaSearch } from 'react-icons/fa'
import { AiOutlineFileSearch } from 'react-icons/ai'
import './styles.css'
import { ChartBar } from '../../helpers/chart-bar'
import { ChartPie } from '../../helpers/chart-pie'
import { ChartLine } from '../../helpers/chart-line'

const { Title, Text } = Typography

const Stats = () => {
    const [ searchResult, setSearchResult] = useState('')

    const handleChange = (e) => {
        setSearchResult(e.target.value)
    }   
    return (
        <>
            <Row gutter={[20, 52]} align={'middle'} justify={'center'} style={{ marginBottom: 30 }}>
                <Col span={24} className='searchBar'>
                    <Input placeholder='Search for a record...' prefix={<FaSearch color='#888' size={18} style={{marginRight: 10}}/>} className='searchInput' onChange={handleChange}/>
                    <Button className='searchBtn'>Search</Button>
                </Col>
            </Row>
            <Row gutter={[20, 52]} align={'middle'} justify={'center'} style={{ marginBottom: 30 }}>
                { searchResult !== ''?
                    <Col span={24} className='searchResult'>
                        <Title className='searchResultTitle'><AiOutlineFileSearch color='#888' size={25} /> Results</Title>
                        <Text className='searchResultText'>{searchResult}</Text>
                    </Col> : null
                }
            </Row>
            <Row gutter={[20, 52]} align={'middle'} justify={'center'} style={{ marginBottom: 30 }}>
                <Col xs={24} md={18}>
                    <ChartLine />
                </Col>
                <Col xs={24} md={12}>
                    <ChartPie />
                </Col>
                <Col xs={24} md={12}>
                    <ChartBar />
                </Col>
            </Row>
        </>
    )
}

export default Stats