import React from 'react'
import police from '../assets/police-service.png'
import nadmo from '../assets/nadmo-service.png'
import ambulance from '../assets/ambulance-service.png'
import fire from '../assets/fire-service.png'
import security from '../assets/security_badge.png'


export const AdminLogo = ({ logoIcon, affiliate }) => {
    return (
        affiliate === 'Ghana Police Service' ?
            <img src={police} alt={affiliate} className={logoIcon} /> :
            affiliate === 'Ghana Ambulance Service' ?
                <img src={ambulance} alt={affiliate} className={logoIcon} /> :
                affiliate === 'Ghana Fire Service' ?
                    <img src={fire} alt={affiliate} className={logoIcon} /> :
                    affiliate === 'Ghana Nadmo Service' ?
                        <img src={nadmo} alt={affiliate} className={logoIcon} /> :
                        <img src={nadmo} alt={security} className={logoIcon} />

    )
}

export default AdminLogo