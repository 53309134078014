import React, { useEffect, useState } from 'react'
import Preloader from '../helpers/preloader'
import Layout from '../layout'
import Setting from '../components/settings'
import { useAtomValue } from 'jotai'
import { staffData } from '../store'

const Settings = () => {
    const [isLoading, setLoading] = useState(true)
    const agents = useAtomValue(staffData)

    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        isLoading ? <Preloader/> :
        <Layout title={'Settings'}>
            <Setting agents={agents}/>
        </Layout>
    )
}

export default Settings