import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { colors } from './chart-colors';
import { BarChartData } from './compute-data';
import { useAtomValue } from 'jotai';
import { tokenData } from '../store';


export const ChartBar = () => {
    const user = useAtomValue(tokenData)
    const complaints = []
    const { Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec } = BarChartData(complaints.filter(item => item.category === user.affiliate))

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Monthly digest',
                data: [Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec],
                ...colors
            }
        ]
    }
    return (
        <Bar data={data}
            width={450}
            height={250}
            options={{
                maintainAspectRatio: false,
            }} />
    )
}