import { createStore, atom } from 'jotai'
import { atomWithStorage} from 'jotai/utils'

const store = createStore()

export const jwt_token = atomWithStorage('token', '')
export const tokenData = atom({})
export const complaintData = atom([])
export const usersData = atom([])
export const staffData = atom([])

// export default store;