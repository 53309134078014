import React from 'react'

import AlertCard from './complaints';

const Notification = () => {
    return (
        <AlertCard/>
    );
}

export default Notification