import React, { useEffect, useState } from 'react'
import Preloader from '../helpers/preloader'
import LandingPage from '../components/landing'

const Landing = () => {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])
  return (
    isLoading ? <Preloader /> :
      <LandingPage />
  )
}

export default Landing