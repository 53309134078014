import React, { useEffect, useState } from 'react'
import Preloader from '../helpers/preloader'
import { useParams, Link } from "react-router-dom";
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import Layout from '../layout';
import Preview from '../components/complaints/complaints-preview'
import { useAtomValue } from 'jotai';
import { complaintData } from '../store';

const PreviewPage = () => {
  // States
  const [isLoading, setLoading] = useState(true)
  
  const params = useParams()

  const complaints = useAtomValue(complaintData)

  const linkStyle = {
    textDecoration: 'none',
    color: '#aaa',
    display: 'flex',
    alignItems: 'center'
  }

  useEffect(() => {
    setLoading(false)
  }, [])


  return (
    isLoading ? <Preloader /> : 
    <Layout title={
      <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
        <Link to={'/admin/complaints/'} style={linkStyle}><IoArrowBackCircleOutline style={{ fontSize: 40}}/></Link>
        <span>Complaint Preview</span>
      </div>}>
      <Preview complaint={complaints.filter(item => item._id.toString() === params.id)} />
    </Layout>
  )
}

export default PreviewPage