import { Routes, Route, useNavigate } from "react-router-dom";
import Landing from "../pages/landing";
import Dashboard from "../pages/dashboard";
import Complaints from "../pages/complaints";
import Login from "../pages/login";
import Statistics from "../pages/statistics";
import Settings from "../pages/settings";
import PreviewPage from "../pages/complaint-preview";
import Agent from '../pages/agent'
import Profile from '../pages/agent-profile';
import AgentPreview from '../pages/agent-preview';
import Protected from "./protect-route";
import notFound from '../assets/404.svg'


const AppRoutes = () => {
    const navigate = useNavigate( )
    return (
        <Routes>
            {/* General routes */}
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />

            {/* Protected Admin routes */}
            <Route path="/admin/dashboard" element={<Protected><Dashboard /></Protected>} />
            <Route path="/admin/complaints" element={<Protected><Complaints /></Protected>} />
            <Route path="/admin/complaints/:id" element={<Protected><PreviewPage /></Protected>} />
            <Route path="/admin/statistics" element={<Protected><Statistics /></Protected>} />
            <Route path="/admin/settings" element={<Protected><Settings /></Protected>} />

            {/* Protected Agent routes */}
            <Route path="/staff/home" element={<Protected><Agent /></Protected>} />
            <Route path="/staff/home/:id" element={<Protected><AgentPreview /></Protected>} />
            <Route path="/staff/profile" element={<Protected><Profile /></Protected>} />

            <Route path="*" element={
            <div className='not-found'>
                    <img src={notFound} alt=""/>
                <button onClick={() => navigate('/')} >Go Home</button>
            </div>
        }/>
        </Routes>

    )
}

export default AppRoutes