import React from 'react'
import {Row, Col, Button, Typography} from 'antd'
import {useNavigate} from 'react-router'
import Navigation from './side-nav'
import './styles.css'
import {FaSignOutAlt} from 'react-icons/fa'
import {useAtomValue, useSetAtom} from "jotai";
import {jwt_token, tokenData} from "../store";

const {Title, Text} = Typography

const Layout = (props) => {
    const navigate = useNavigate();

    const clearSession = useSetAtom(jwt_token);
    const user = useAtomValue(tokenData)

    const handleLogout = () => {
        localStorage.removeItem('token')
        clearSession('')
        navigate('/login')
    }

    return (
        <div className='parent'
             style={{display: 'flex', justifyContent: 'space-between', position: 'relative', overflowX: 'hidden',}}>
            <Navigation setActiveTab={props.setActiveTab} count={props.count} affiliate={user?.affiliate}/>
            <Row className='mainContent'>
                <Col span={24}>
                    <Text><strong>@Admin :</strong> {user?.username}</Text>
                </Col>
                <Col span={24} className='stickyNav'>
                    <Title style={{margin: 0, color: '#090036'}} level={2}>{props.title}</Title>
                    <Button className='logoutBtn' onClick={handleLogout}>
                        <Text style={{color: '#fff', fontWeight: 500}} className='logoutText'>Logout</Text>
                        <FaSignOutAlt size={20}/>
                    </Button>
                </Col>
                <Col span={24} style={{paddingTop: 10}}>
                    {props.children}
                </Col>
            </Row>
        </div>
    )
}

export default Layout