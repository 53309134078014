import React from 'react'
import { Card } from 'antd'
import './styles.css'
import CardHeader from './card-header'
import Form from './form'

const LoginForm = () => {
  return (
    <div className="layout">
      <Card bordered={false} className={'login-card'}>
        <CardHeader />
        <Form />
      </Card>
    </div>
  )
}

export default LoginForm