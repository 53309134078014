import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { FaUser, FaSearch } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { BsFillTelephoneFill, BsEnvelopeFill } from 'react-icons/bs'
import { RiShieldStarFill } from 'react-icons/ri'
import { Card, Row, Col, Button, Input, Typography, Skeleton, message } from 'antd'
import { updateAgent } from '../../helpers/form-validation'
import './styles/update.css'
import { ApiClient } from '../../axios-client'


const { Title, Text } = Typography

const UpdateForm = ({ agents }) => {
    // invoke Navigator
    const Navigate = useNavigate()

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [rank, setRank] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [searchResults, setSearchResults] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [details, setDetails] = useState({})

    const handleDetails = (value) => {
        value.contact = value.contact[0] === 0 ? value.contact : '0'+value.contact
        setDetails(value)
        setSearchResults('')
        setFirstname(value.firstname)
        setLastname(value.lastname)
        setEmail(value.email)
        setRank(value.rank)
        setContact(value.contact)
        setDisabled(false)
    }

    const reset = () => {
        setFirstname('')
        setLastname('')
        setRank('')
        setContact('')
        setSearchResults('')
        setDisabled(true)
        setSearchResults('')
    }

    const handleChange = (e) => {
        setSearchResults(e.target.value)
    }

    const handleUpdate = async () => {
        const { error } = updateAgent.validate({ firstname, lastname, email, rank, contact })

        if (error) {
            message.error(error.message === '"Id" is required' ? 'Agent ID is required' : error.message)
        } else if (details.firstname === firstname && details.lastname === lastname && details.rank === rank && details.email === email && details.contact === contact) {
            message.warning('No changes made yet.')
        }
        else {
            const data = {
                firstname,
                lastname,
                email,
                rank, 
                contact
            }
            await ApiClient.patch(`/user/${details._id}`, data)
                .then(res => {
                    message.success('Agent details updated successfully.')
                    reset()
                    Navigate('/admin/dashboard')
                }).catch(() => message.error('Could not update account, try again.'))
        }
    }
    return (
        <>
            <Row gutter={[20, 52]} align={'middle'} justify={'center'} style={{ marginBottom: 30 }}>
                <Col span={24} className={'searchBar'}>
                    <Input placeholder='Search agent here' prefix={<FaSearch color='#888' size={18} style={{ marginRight: 10 }} />} className={'searchInput'} onChange={handleChange} />
                    <Button className={'searchBtn'}>Search</Button>
                </Col>
            </Row>
            <Row gutter={[20, 20]} style={{ marginBottom: 30 }}>
                {
                    searchResults === '' ?
                        <Col xs={24} lg={12}>
                            <Title level={4} style={{ color: '#ccc' }}>No search results, Please search ... </Title>
                            <Skeleton loading={true} active avatar />
                        </Col> :
                        <Col xs={24} lg={12}>
                            {agents.filter((item) => {
                                if (searchResults === '') {
                                    return item
                                } else if (item.firstname.toLowerCase().includes(searchResults.toLowerCase()) || item.lastname.toLowerCase().includes(searchResults.toLowerCase()) || item.email.toLowerCase().includes(searchResults.toLowerCase())) {
                                    return item
                                }
                            }).map((item, index) => {
                                return (
                                    <Card key={index} hoverable className={'userCard userCard'}>
                                        <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 15 }}>
                                            <div className={'icon'}><FaUser size={25} color={'#fff'} /></div>
                                            <Text className={'username'}>{item.firstname + ' ' + item.lastname}</Text>
                                        </div>
                                        <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Button className={'activateEdit'} onClick={() => handleDetails(item)}> <MdEdit size={20} /></Button>
                                        </div>
                                    </Card>
                                )
                            })
                            }
                        </Col>
                }
                <Col xs={24} lg={12} className={'form'}>
                    <Card className={'updateCard registerCard'}>
                        <Row justify='center' align='midde' gutter={[20, 20]} className={'cardRow'}>
                            <Col span={24} style={{ textAlign: 'center' }}><Title level={4} style={{ color: '#00115b', fontWeight: 600 }}>Edit Agent</Title></Col>
                            <Col span={24}>
                                <Input disabled={disabled} prefix={<FaUser color='#91A2B8' size={16} style={{ marginRight: 10 }} />} type={'text'} placeholder='Agent Name' value={firstname} className={'input'} onChange={(e) => setFirstname(e.target.value)} />
                            </Col>
                            <Col span={24}>
                                <Input disabled={disabled} prefix={<FaUser color='#91A2B8' size={16} style={{ marginRight: 10 }} />} type={'text'} placeholder='Agent Name' value={lastname} className={'input'} onChange={(e) => setLastname(e.target.value)} />
                            </Col>
                            <Col span={24}>
                                <Input disabled={disabled} prefix={<BsEnvelopeFill color='#91A2B8' size={16} style={{ marginRight: 10 }} />} type={'text'} placeholder='Email' value={email} className={'input'} onChange={(e) => setEmail(e.target.value)} />
                            </Col>
                            <Col span={24}>
                                <Input disabled={disabled} prefix={<RiShieldStarFill color='#91A2B8' size={16} style={{ marginRight: 10 }} />} type={'text'} placeholder='Position/Rank' value={rank} className={'input'} onChange={(e) => setRank(e.target.value)} />
                            </Col>
                            <Col span={24}>
                                <Input disabled={disabled} prefix={<BsFillTelephoneFill color='#91A2B8' size={16} style={{ marginRight: 10 }} />} type={'text'} placeholder='Contact' value={contact} className={'input'} onChange={(e) => setContact(e.target.value)} />
                            </Col>
                            <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button className={'btn'} disabled={disabled} onClick={handleUpdate}>Update</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default UpdateForm