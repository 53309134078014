import { useEffect, useCallback } from 'react'
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import { message } from 'antd'
import 'antd/dist/antd.min.css'
import AppRoutes from './routes';
import { ApiClient } from './axios-client';

function App() {
  // const dispatcher = useDispatch()
  // const navigate = useNavigate()
  //
  // const fetchComplaints = useCallback(async (cat) => await ApiClient.get(`/get-complaints/?cat=${cat}`)
  //   .then(res => dispatcher(getAllComplaints(res.data)))
  //   .catch(err => message.error(err.message))
  //   , [dispatcher])
  //
  // const fetchUsers = useCallback(async () => await ApiClient.get('/count-users')
  //   .then(res => dispatcher(getAllUsers(res.data)))
  //   .catch(err => message.error(err.message))
  //   , [dispatcher])
  //
  // const fetchAgents = useCallback(async (cat) => await ApiClient.get(`/agents/?cat=${cat}`)
  //   .then(res => dispatcher(getAllAgents(res.data)))
  //   .catch(err => message.error(err.message))
  //   , [dispatcher])

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   if (token) {
  //     const { user } = jwt_decode(token);
  //     dispatcher(authUser(user))
  //     fetchComplaints(user.affiliate);
  //     fetchAgents(user.affiliate);
  //     fetchUsers();
  //     if (!user) {
  //       localStorage.removeItem('token')
  //       navigate('/')
  //     }
  //   }
  // }, [fetchComplaints, fetchAgents, fetchUsers, dispatcher, navigate])

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
