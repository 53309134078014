import React from 'react'
import { Spin } from 'antd'

const Preloader = () => {
    const loaderStyles = {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
    return(
        <div style={loaderStyles}>
            <Spin size='large' spinning />
        </div>
    )
}

export default Preloader