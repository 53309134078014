import React from 'react'
import { Row, Col, Typography, Button } from 'antd'
import { Link } from 'react-router-dom'
import './styles.css'
import { BiLogIn } from 'react-icons/bi'
import Logo from '../../assets/logo.png'
import Flag from '../../assets/flag.gif'

const LandingPage = () => {

    const { Title, Text } = Typography
    return (
        <Row align='middle' justify='center' className='container'>
            <Col xs={16} md={12} lg={10} className='home-card'>
                <Row align='middle' justify='center' gutter={[0, 40]} style={{ padding: '40px 20px' }}>
                    <Col span={24} className='styling-gel' style={{ flexDirection: 'column', gap: 50 }}>
                        <div>
                            <Title level={3} style={{ fontWeight: 700, textAlign: 'center', margin: 0, padding: 0, color: '#24E9A3' }}>Welcome to</Title>
                            <Title level={3} style={{ textAlign: 'center', margin: 0, padding: 0, textTransform: 'uppercase', color: '#91A2B8' }}>Ghana Emergency Service</Title>
                        </div>
                        <img src={Logo} alt="Gems Logo" className='landing-logo' />
                    </Col>
                    <Col span={24} className='styling-gel' style={{ flexDirection: 'column', gap: 50 }}>
                        <Title level={5} style={{ fontWeight: 'normal', color: '#91A2B8', textAlign: 'center' }}>Virtual platform to communicate complaints</Title>
                        <img src={Flag} alt="Ghana Flag" width={50} />
                    </Col>
                    <Col span={24} className='styling-gel'>
                        <Link to={'/login'}>
                            <Button className='landing-btn circular'><BiLogIn className='landing-icon' /></Button>
                        </Link>
                        <Link to={'/login'}>
                            <Button className='landing-btn rectangular'><Text className='landing-btn-text'>Proceed to Login to your dashboard</Text></Button>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default LandingPage