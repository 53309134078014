import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Row, Col, Typography, Input, Divider, Button, message } from 'antd'
import { BiUser, BiLock, BiLogIn } from 'react-icons/bi'
import { BsArrowReturnLeft } from 'react-icons/bs'
import jwt_decode from "jwt-decode";
import './styles.css'
import { loginSchema } from '../../helpers/form-validation'
import { ApiClient } from '../../axios-client'
import { useAtomValue, useSetAtom } from "jotai";
import { jwt_token, tokenData } from '../../store'

const { Text } = Typography

const Form = () => {
    const [username, setUsername] = useState('')
    const [ID, setID] = useState('')
    const setToken = useSetAtom(jwt_token)
    const setTokenData = useSetAtom(tokenData)
    const token = useAtomValue(jwt_token)
    const userData = useAtomValue(tokenData)

    useEffect(() => {
        if (token) {
            const { user } = jwt_decode(token)
            return setTokenData(user)
        }
    }, [token, setTokenData]);

    useEffect(() => {
        if(token) return;
    })

    const navigate = useNavigate()

    const handleLogin = async () => {
        const { error } = loginSchema.validate({ username, ID })
        if (error) message.error({ content: error.message, duration: 5, style: { color: 'red' } });
        else {
            try {
                const res = await ApiClient.post(`/auth/login`, { email: username, password: ID })
                if (res.status === 200) {
                    setToken(res.data?.token)
                }
                
                if (userData?.role === 'staff') {
                    navigate('/staff/home')
                } else if (userData?.role === 'admin') {
                    navigate('/admin/dashboard')
                } else {
                    message.warning('Invalid user role.')
                }


            } catch (error) {
                message.error(error.response?.data?.message?.clientMsg)
            }
        }
    }
    return (
        <Row>
            <Col span={24}><Text className={'loginText'}>Admin Login</Text></Col>
            <Col span={24}>
                <div className={'inputArea'}>
                    <Input
                        defaultValue={username}
                        type={'text'}
                        prefix={<BiUser className={'formIcon'} />}
                        placeholder="Email address"
                        bordered={false}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <Divider className={'divider'} />
                    <Input.Password
                        defaultValue={ID}
                        prefix={<BiLock className={'formIcon'} />}
                        placeholder="Password"
                        bordered={false}
                        style={{ paddingRight: 10 }}
                        onChange={(e) => setID(e.target.value)}
                    />
                </div>
                <div className="loginArea">

                    <Button className={'loginBtn'} title='Login' onClick={handleLogin}>
                        <Text style={{ color: '#fff' }}>Login</Text>
                        <BiLogIn className={'loginBtnIcon'} />
                    </Button>
                </div>
                <Text className={'backHome'}>
                    Go back &nbsp;
                    <Link to={'/'}>
                        <Text style={{
                            color: '#24E9A3',
                            textDecoration: 'underline',
                            fontWeight: 500
                        }}><BsArrowReturnLeft />Home</Text>
                    </Link>
                </Text>
            </Col>
        </Row>
    )
}

export default Form