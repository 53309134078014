import React from 'react'
import { Row, Col } from 'antd'
import Logo from '../../helpers/logo'
import './styles.css'

const CardHeader = () => {
  return (
    <Row justify={'center'} align={'middle'}>
        <Col xs={24} className={'cardHeader'}>
            <Logo dim={100}/>
        </Col>
    </Row>
  )
}

export default CardHeader