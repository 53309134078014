import { Pie } from 'react-chartjs-2';
import { colors } from './chart-colors';
import { PieChartData } from './compute-data';
import { useAtomValue } from 'jotai';
import { complaintData, tokenData } from '../store';


export const ChartPie = () => {
    const user = useAtomValue(tokenData)
    const complaints = useAtomValue(complaintData);
    const types = PieChartData(complaints)

    const policeTypes = ['Crime', 'Homicide', 'Violence / Assault', 'Other']
    const fireTypes = ['Fire outbreak', 'Other']
    const ambulanceTypes = ['Emergency Health Issue', 'Death', 'Other']
    const ecgTypes = ['Environmental hazards', 'Power outage', 'Other']
    const localTypes = ['Sanitory Issues', 'Other']

    const gen = (data, type) => {
        return data.filter(item => item === type).length
    }

    let listedTypes
    let labels

    if(user.affiliate === 'Ghana Police Service'){
        labels = [...policeTypes];
        listedTypes = [gen(types, policeTypes[0]), gen(types, policeTypes[1]), gen(types, policeTypes[2]), gen(types, policeTypes[3])]
    } else if (user.affiliate === 'Ghana Fire Service'){
        labels = [...fireTypes];
        listedTypes = [gen(types, fireTypes[0]), gen(types, fireTypes[1])]
    } else if (user.affiliate === 'Ghana Ambulance Service') {
        labels = [...ambulanceTypes];
        listedTypes = [gen(types, ambulanceTypes[0]), gen(types, ambulanceTypes[1]), gen(types, ambulanceTypes[2])]
    } else if (user.affiliate === 'Electricity Company of Ghana (ECG)') {
        labels = [...ecgTypes];
        listedTypes = [gen(types, ecgTypes[0]), gen(types, ecgTypes[1]), gen(types, ecgTypes[2])]
    } else if (user.affiliate === 'Local Assembly') {
        labels = [...localTypes];
        listedTypes = [gen(types, localTypes[0]), gen(types, localTypes[1])]
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Reported types',
                data: listedTypes,
                ...colors
            }
        ]
    }
    return (
        <Pie data={data}
            width={450}
            height={250}
            options={{
                maintainAspectRatio: false,
            }}
        />
    )
}
