
// Compute today's date
export const TodaysDates = (complaints) => {
    let today = complaints.map(data => ({
        today: new Date(data.createdAt.split('T')[0]).getDate() === new Date(Date.now()).getDate() && data.createdAt.split('T')[0]
    }))
    today = today.filter(item => item.today)

    return today.length
}

// Compute last month's dates
export const LastMonthsDates = (complaints) => {
    let lastMonth = complaints.map(data => ({
        lastMonth: new Date(data.createdAt.split('T')[0]).getMonth() === new Date(Date.now()).getMonth() && data.createdAt.split('T')[0]
    }))
    lastMonth = lastMonth.filter(item => item.lastMonth)

    return lastMonth.length
}