import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd'
import { BsStack } from 'react-icons/bs'
import { IoToday } from 'react-icons/io5'
import { FaCalendarAlt, FaUserCheck } from 'react-icons/fa'
import { AnalyticsCard, UsersCard } from './card';
import { ChartLine } from '../../helpers/chart-line'
import { ChartBar } from '../../helpers/chart-bar';
import { ChartPie } from '../../helpers/chart-pie';
import { TodaysDates, LastMonthsDates } from '../../helpers/compute-dates';
import { useAtomValue } from 'jotai';
import { complaintData, usersData } from '../../store';

const Home = () => {
    // States
    const [people, setPeople] = useState(0)
    const [complaintsTotal, setComplaintsTotal] = useState(0)
    const [complaintsMonth, setComplaintsMonth] = useState(0)
    const [complaintsToday, setComplaintsToday] = useState(0)
    const users = useAtomValue(usersData)
    const complaints = useAtomValue(complaintData)

    useEffect(() => { 
        setPeople(users.length)
        setComplaintsTotal(complaints.length)
        setComplaintsToday(TodaysDates(complaints))
        setComplaintsMonth(LastMonthsDates(complaints))
    }, [complaints, users])

    return (
        <>
            <Row gutter={[30, 10]} style={{ marginBottom: 40 }}>
                <Col xs={12} sm={8} md={6}>
                    <AnalyticsCard label='All time' cardBg={'rgba(27,202,205,0.3)'} textColor={'rgb(20,100,255)'} percent={complaintsTotal / 100} typeText={'Total'} icon={<BsStack size={20} color={'#00115B'} />} typeNumber={complaintsTotal} />
                </Col>
                <Col xs={12} sm={8} md={6}>
                    <AnalyticsCard label='This month' cardBg={'rgba(251,181,0,0.3)'} textColor={'rgb(255,150,0)'} percent={complaintsMonth / 100} typeText={'Complaints'} icon={<FaCalendarAlt size={20} color={'#00115B'} />} typeNumber={complaintsMonth} />
                </Col>
                <Col xs={12} sm={8} md={6}>
                    <AnalyticsCard label='complaints today ' cardBg={'rgba(233,36,36,0.3)'} textColor={'rgb(200,36,36)'} percent={complaintsToday / 100} typeText={'Issues'} icon={<IoToday size={20} color={'#00115B'} />} typeNumber={complaintsToday} />
                </Col>
                <Col xs={12} sm={8} md={6}>
                    <UsersCard label='' cardBg={'rgba(216, 216, 216, 0.3)'} textColor={'rgb(130, 130, 130)'} icon={<FaUserCheck size={20} color={'#00115B'} />} typeText={'Users'} typeNumber={people} />
                </Col>
            </Row>
            <Row gutter={[12, 30]} style={{ marginBottom: 50 }}>
                <Col xs={24} md={12}>
                    <ChartBar />
                </Col>
                <Col xs={24} md={12}>
                    <ChartPie />
                </Col>
                <Col xs={24}>
                    {/* <ChartLine /> */}
                </Col>
            </Row>

        </>
    );
}

export default Home;
