import React, { useState } from 'react'
import { Row, Col, Button, Modal, Typography, Input, message } from 'antd'
import { ImProfile } from 'react-icons/im'
import { useNavigate } from 'react-router-dom'
import { IoMdLock } from 'react-icons/io'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import './styles.css'
import { ApiClient } from '../../axios-client'
import { useAtomValue, useSetAtom } from "jotai";
import { jwt_token, tokenData } from "../../store";


const ProfilePage = () => {
    const [visible, setVisible] = useState(false)
    const [done, setDone] = useState(false)
    const [password, setPassword] = useState('')
    const [repassword, setRepassword] = useState('')

    const user = useAtomValue(tokenData)


    const { Title } = Typography
    const navigate = useNavigate()

    const clearSession = useSetAtom(jwt_token)

    const signOut = () => {
        clearSession('')
        navigate('/')
    }

    const reset = () => {
        setPassword('');
        setRepassword('')
    }

    const handleProceed = async () => {
        if (password === '' && repassword === '') {
            message.warning('Please change your password.')
        } else if (password !== repassword) {
            message.warning('Passwords do not match.')
        } else if (password === repassword) {
            ApiClient.patch(`/user/${user._id}`, { password })
                .then(() => {
                    reset()
                    setDone(true)
                    setTimeout(() => {
                        setDone(false);
                        signOut()
                    }, 1000)
                })
                .catch(() => message.error('Couldnt change password, try again.'))
        } else {
            message.error('Something happened, try again.')
        }
    }
    const { firstname, lastname, username, email, rank, affiliate, contact } = user;

    return (
        <Row align='middle' justify='center' className='profiler' gutter={[0, 20]}>
            <Col xs={24}>
                <Title level={4} style={{
                    margin: 0,
                    fontWeight: 700,
                    color: '#00115b',
                    display: 'flex',
                    alignItems: 'center'
                }}><ImProfile size={25} /> Profile</Title>
            </Col>
            <Col xs={24}>
                <Title level={5} style={{ margin: 0 }}>Name</Title>
                <Input disabled value={firstname + ' ' + lastname} style={{ color: '#00115b', borderRadius: 8 }} />
            </Col>
            <Col xs={24}>
                <Title level={5} style={{ margin: 0 }}>Username</Title>
                <Input disabled value={username} style={{ color: '#00115b', borderRadius: 8 }} />
            </Col>
            <Col xs={24}>
                <Title level={5} style={{ margin: 0 }}>Email Address</Title>
                <Input disabled value={email} style={{ color: '#00115b', borderRadius: 8 }} />
            </Col>
            <Col xs={24}>
                <Title level={5} style={{ margin: 0 }}>Rank</Title>
                <Input disabled value={rank} style={{ color: '#00115b', borderRadius: 8 }} />
            </Col>
            <Col xs={24}>
                <Title level={5} style={{ margin: 0 }}>Affiliation</Title>
                <Input disabled value={affiliate} style={{ color: '#00115b', borderRadius: 8 }} />
            </Col>
            <Col xs={24}>
                <Title level={5} style={{ margin: 0 }}>Contact</Title>
                <Input disabled value={'0' + contact} style={{ color: '#00115b', borderRadius: 8 }} />
            </Col>
            <Col xs={24}>
                <Button onClick={() => setVisible(true)} className='cp-btn'>Change Password</Button>
            </Col>
            <Modal closable={false} visible={visible} footer={null}
                style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
                {
                    done ? <div className='done-change'>
                        <IoMdLock size={70} color={'#e92424b3'} />
                        <BsFillCheckCircleFill size={70} color={'#00c851b3'} />
                    </div> :

                        <Row align='middle' justify='center' gutter={[0, 15]} className='modal'>
                            <Col span={24}>
                                <Title level={4} style={{ color: '#adbcff' }}>Change Password</Title>
                            </Col>
                            <Col span={24} className={'styling-gel'}>
                                <Input.Password placeholder='New Password' value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className='password-input' />
                            </Col>
                            <Col span={24} className={'styling-gel'}>
                                <Input.Password placeholder='Confirm Password' value={repassword}
                                    onChange={(e) => setRepassword(e.target.value)}
                                    className='password-input' />
                            </Col>
                            <Col span={24}
                                style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', gap: 20 }}>
                                <Button onClick={handleProceed} className='proceed-password-btn'>Proceed</Button>
                                <Button onClick={() => {
                                    reset()
                                    setVisible(false)
                                }} className='cancel-password-btn'>Cancel</Button>
                            </Col>
                        </Row>
                }
            </Modal>

        </Row>
    )
}

export default ProfilePage