import React, { useEffect, useState } from 'react'
import Preloader from '../helpers/preloader'
import Layout from '../layout'
import Stats from '../components/statistics'

const Statistics = () => {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])
  return (
    isLoading ? <Preloader /> :
    <Layout title={'Statistics'}>
        <Stats/>
    </Layout>
  )
}

export default Statistics