export const BarChartData = (complaints) => {
    let values = []
    for(let i = 0; i < complaints.length; i++){
        values.push(new Date(complaints[i].date).getMonth())
    }

    return  {
        Jan: values.filter(value => value === 0).length,
        Feb: values.filter(value => value === 1).length,
        Mar: values.filter(value => value === 2).length,
        Apr: values.filter(value => value === 3).length,
        May: values.filter(value => value === 4).length,
        Jun: values.filter(value => value === 5).length,
        Jul: values.filter(value => value === 6).length,
        Aug: values.filter(value => value === 7).length,
        Sep: values.filter(value => value === 8).length,
        Oct: values.filter(value => value === 9).length,
        Nov: values.filter(value => value === 10).length,
        Dec: values.filter(value => value === 11).length,
    }
}

export const PieChartData = (complaints) => {
    let types = []
    for (let i = 0; i < complaints.length; i++) {
            types.push(complaints[i].type)
    }
    return types
}