import React, { useState, useEffect } from 'react'
import { Modal, Input, Typography, Row, Col, Button, message } from 'antd'
// import bcrypt from 'bcrypt'
import { useNavigate } from 'react-router-dom'
import { IoMdLock } from 'react-icons/io'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import Navbar from './navbar'
import AlertCard from './complaints'
import './styles.css'
import { ApiClient } from '../../axios-client'
import { useAtomValue, useSetAtom } from "jotai";
import { jwt_token, tokenData } from "../../store";


const Homepage = () => {
    const [visible, setVisible] = useState(false)
    const [password, setPassword] = useState('')
    const [repassword, setRepassword] = useState('')
    const [done, setDone] = useState(false)

    const navigate = useNavigate()

    const user = useAtomValue(tokenData)
    const { isFirstTime } = user

    useEffect(() => {
        if (isFirstTime) {
            setVisible(true)
        }
    }, [])

    const { Title, Text } = Typography


    const clearSession = useSetAtom(jwt_token)
    const signOut = () => {
        clearSession('')
        navigate('/login')
    }

    const handleProceed = async () => {
        if (password === '' && repassword === '') {
            message.warning('Please change your password.')
        } else if (password !== repassword) {
            message.warning('Passwords do not match.')
        } else if (password === repassword) {
            ApiClient.patch(`/user/${user._id}`, { password, isFirstTime: false })
                .then(() => {
                    setDone(true)
                    setTimeout(() => {
                        setDone(false);
                        signOut()
                    }, 1000)
                })
                .catch(() => message.error('Couldnt change password, try again.'))
        } else {
            message.error('Something happened, try again.')
        }
    }

    return (
        <>
            <Navbar navText={'Profile'} to={'/staff/profile'} />
            <AlertCard />

            <Modal closable={false} visible={visible} footer={null}
                style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
                {
                    done ? <div className='done-change'>
                        <IoMdLock size={70} color={'#e92424b3'} />
                        <BsFillCheckCircleFill size={70} color={'#00c851b3'} />
                    </div> :

                        <Row align='middle' justify='center' gutter={[0, 15]} className='modal'>
                            <Col span={24}>
                                <Title level={4} style={{ color: '#adbcff' }}>Change Password</Title>
                            </Col>
                            <Col span={24}>
                                <Text style={{ color: '#00115b' }}>Dear {user.firstname}, your
                                    password was pre-generated. Kindly change it below.</Text>
                            </Col>
                            <Col span={24} className={'styling-gel'}>
                                <Input.Password placeholder='New Password' value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className='password-input' />
                            </Col>
                            <Col span={24} className={'styling-gel'}>
                                <Input.Password placeholder='Confirm Password' value={repassword}
                                    onChange={(e) => setRepassword(e.target.value)}
                                    className='password-input' />
                            </Col>
                            <Col span={24} className={'styling-gel'}>
                                <Button onClick={handleProceed} className='proceed-btn'>Proceed</Button>
                            </Col>
                        </Row>
                }
            </Modal>
        </>
    )
}

export default Homepage