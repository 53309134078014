import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Row, Col, Button, Card, Typography, Input, Modal, Skeleton, message } from 'antd'
import { FaUser, FaSearch, FaTrash } from 'react-icons/fa'
import './styles/delete.css'
import { ApiClient } from '../../axios-client'

const { Title, Text } = Typography

const DeleteForm = ({ agents }) => {
    const Navigate = useNavigate()
    // States
    const [searchResults, setSearchResults] = useState('')
    const [visible, setVisible] = useState(false)
    const [user, setUser] = useState('')

    const handleClick = (user) => {
        setUser(user)
        setVisible(true)
    }

    const handleConfirm = async () => {
        await ApiClient.delete(`/user/${user._id}`)
            .then((res) => {
                message.success(res.data.message)
                Navigate('/admin/dashboard')
            })
            .catch((error) => message.error(error?.response?.data?.message))
        setVisible(false)
    }

    return (
        <Row gutter={[20, 20]}>
            <Col span={24} className={'searchBar'}>
                <Input placeholder='Search agent here' prefix={<FaSearch color='#888' size={18} style={{ marginRight: 10 }} />} className={'searchInput'} onChange={(e) => { setSearchResults(e.target.value) }} />
                <Button className={'searchBtn'}>Search</Button>
            </Col>

            {
                Object.keys(agents).length === 0 ?
                    <Col xs={24} lg={12}>
                        <Title level={4} style={{ color: '#ccc' }}>No Agents ... </Title>
                        <Skeleton loading={true} active avatar />
                    </Col> :

                    agents.filter(item => {
                        if (searchResults === '') {
                            return item
                        } else if (item.name.toLowerCase().includes(searchResults.toLowerCase())) {
                            return item
                        }
                    }).map((user, _) => {
                        return (
                            <Col key={_} xs={24} md={12}>
                                <Card hoverable className={'userCard userCard'}>
                                    <div style={{ width: '90%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 15 }}>
                                        <div className={'icon'}><FaUser size={25} color={'#fff'} /></div>
                                        <Text className={'username'}>{user.firstname + " " + user.lastname}</Text>
                                    </div>
                                    <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 15 }}>
                                        <FaTrash className={'deleteUser'} size={20} onClick={() => handleClick(user)} />
                                    </div>
                                </Card>
                            </Col>
                        )
                    })
            }
            <Modal footer={false} visible={visible} closeIcon={<></>} closable={true}>
                <Row gutter={[10, 10]} style={{ padding: 20 }}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Title level={4} style={{ color: '#e92424cc' }}>Confirm</Title>
                    </Col>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Text>Are you sure you want to delete <b>{user.name}</b></Text>
                    </Col>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                        <Button onClick={handleConfirm} className={'confirmBtn'}>Confirm</Button>
                        <Button onClick={() => setVisible(false)} className={'cancelBtn'}>Cancel</Button>
                    </Col>
                </Row>
            </Modal>
        </Row>
    )
}

export default DeleteForm